import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HubConnection, HubConnectionBuilder, HubConnectionState, HttpTransportType } from '@microsoft/signalr';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectionResolver {
  public conversationHub!: HubConnection;
  public closed = new BehaviorSubject<boolean>(false);
  public conversationHubStatus$ = new BehaviorSubject<boolean>(true);

  public startconnection(authToken: string): void {
    this.conversationHub = new HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/hubs/conversations`, {
        accessTokenFactory: () => authToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    if (!this.conversationHub || this.conversationHub.state === HubConnectionState.Disconnected || this.conversationHubStatus$.value) {
      this.startHub();
    }

    this.conversationHub.onclose(async () => {
      if (!this.closed.value) {
        console.warn('WebSocket disconnected, trying o reconnect...');
        this.conversationHubStatus$.next(true);
        await this.reconnectWithDelay();
      }
    });
  }

  private async startHub(): Promise<void> {
    try {
      await this.conversationHub.start();
      this.conversationHubStatus$.next(false);
    } catch (err) {
      console.error('Error while trying to connect to WS:', err);
      this.conversationHubStatus$.next(true);
    }
  }

  private async reconnectWithDelay(): Promise<void> {
    let retries = 0;
    const maxRetries = 5;
    while (retries < maxRetries && !this.closed.value) {
      try {
        console.log(`Reconnection try: #${retries + 1}...`);
        await this.startHub();
        console.log('Reconnected!');
        return;
      } catch (err) {
        console.error('Failed to reconnect, new try in ', 2 ** retries * 1000, 'ms');
        retries++;
        await new Promise(res => setTimeout(res, 2 ** retries * 1000));
      }
    }
    console.error('Impossible to reconnect after ', maxRetries, ' tries.');
  }

  public sendMessage(method: string, data: any): void {
    if (this.conversationHub && this.conversationHub.state === HubConnectionState.Connected) {
      this.conversationHub.send(method, data).catch(err => console.error('Error while sending message :', err));
    } else {
      console.warn('Impossible to send message WS disconnected.');
    }
  }

  public closeHub(): void {
    if (this.conversationHub) {
      this.conversationHub.stop().then(() => {
        this.closed.next(true);
      });
    }
  }
}
